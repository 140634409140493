.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.icon-button {
    /* display: contents; */
    padding: 0px 0px;
    margin: 0;
    background-color: transparent;
    border: none;
    position: relative;
    width: 50px;
    height: 50px;

    /* care icon shadow */
    padding: 5px 0px 0px 0px;

    /* BUG?? */
    /* CSS variable works without providing default value. */
    /* but it arises compile error when imported if it has relative path. */
    /* react compiler looks like try to compile it as module path relative from tsx*/
	/* --normal_url: url("./icons/icon_unknown_n.png"); */
	/* --pressed_url: url("./icons/icon_unknown_p.png"); */
    img {        
        content:var(--normal_url);
        &:active{
            content: var(--pressed_url)
        }		
        /* must set display:inline-block for use with alt */
        display:inline-block;   
        width: 50px;
        height: 50px;
    }
}

.thumbnail-button {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 5px;

    img {
        width: 40px;
        height: 40px;
    }    
}

.thumbnail-button.selected {
    border: thin solid yellow;
    border-radius: 20px;
}

.main-menu-bar{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: absolute;
}

.sub-menu-bar{
    position: absolute;    
}

.treemenu{
    --depth: 0;
    position: absolute; 
    z-index: 10;
    min-width: 100px; 
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.grid{
    display: inline-flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.default-bg{
    /* background-color: rgba(0, 0, 0, 0.2); */
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    padding: 5px;    
    margin: 5px;
}

.default-bg2{
    --bg_url: url(../public/icons/buttons_basepanel_minimize.png);
    border-width: 40px;
    border-style: solid;
    border-image: var(--bg_url);
    border-image-slice: 49% fill;
    border-image-repeat: stretch;
}

.treemenu-button{
    height: 20px;
    border: none;
    margin: 5px;
}

.spinner {    
    position: absolute; 
    width: 100%; 
    height: 100%;
    left: 0;
}

.spinner-wheel {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;    
    animation: wheel 2s linear infinite;
}
  
@keyframes wheel {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.tab {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
}

.tab .tab-head {
    display: flex;
    flex-wrap: wrap;
}

.tab .tab-body {
    display: inline-flex;
    overflow: scroll;
    scrollbar-width: none;
}

.colorpicker-popup{
    --clientX: 0;
    --clientY: 0;
    /* position: absolute; */
    position: fixed;
    background-color: white;        
    height: 200px;
    border-radius: 30px; 
    display: flex;
    flex-wrap: wrap;
}

.cover{
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.debug-bar{        
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
}

@media (orientation: landscape){
	.main-menu-bar{
		top: 0;
	}

    .sub-menu-bar{                
        right: 0;
        top: 0;
        bottom: 0;
        max-width: 210px;
    }

    .hide-on-landscape{
        display: none;        
    }

    .grid {
        width: 100%;
    }

    .debug-bar{
        position: absolute;        
        left: 0;
		bottom: 0;		
	}

    .treemenu{
        top: calc(100% + 20px * var(--depth));
        left: calc(100px * var(--depth));
        min-width: 100px; 
    }

    .colorpicker-popup{
        left: calc(var(--clientX) - 200px);
        width: 200px;
        top: max(0px, calc(var(--clientY) - 100px));
    }    

    .spinner-wheel {
        right: 0%;
        bottom: 0%;
        position: absolute;
        margin: 10px;
    }
}

@media (orientation: portrait){
	.main-menu-bar{
		bottom: 0;
	}

    .main-menu-bar-hide{
        transition: 1s;
        bottom: -300px;
    }

    .main-menu-bar-show{
        transition: 1s;
        bottom: 0;
    }

    .sub-menu-bar{         
        display: flex;       
        bottom: 0;
        left: 0;
        right: 0;	                
    }

    .sub-menu-bar-hide{
        transition: 1s;
        bottom: -300px;        
    }

    .sub-menu-bar-show{
        transition: 1s;
        bottom: 0;     
    }    

    .grid {        
        --size: 160px;
        flex-direction: column;
        height:var(--size);
    }

    .debug-bar{
		position: absolute;        
        left: 0;
		top: 0;		
	}

    .treemenu{
        bottom: calc(100% + 20px * var(--depth));
        left: calc(100px * var(--depth));
    }

    .sub-sub-menu{
        display: flex;
        flex-direction: column-reverse;
    }

    .hide-on-landscape{
        display: flex;
        flex-direction: column;
        justify-content: center;                
    }

    .colorpicker-popup{        
        left: max(0px, calc(var(--clientX) - 100px));
        width: 200px;
        bottom: calc(100% - var(--clientY));
    }    

    .spinner-wheel {
        right: 0%;
        top: 0%;
        position: absolute;
        margin: 10px;
    }
}

.loader {
    color: #FF3D00;
    font-size: 45px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    transform: translateZ(0);
    animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
  }
  
  @keyframes mltShdSpin {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 
      0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, 
      -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, 
      -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
       -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, 
       -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
       -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, 
       -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 
      0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  
  @keyframes round {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
  }